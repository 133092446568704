import React from "react";
import MultiStepDemo from "./MultistepDemo";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

export default function Checkout() {
  const stripeKey = process.env.REACT_APP_WEB_STRIPE_PUBLIC_KEY || "";
  const stripePromise = loadStripe(stripeKey); // Add this
  return (
    <div className="container mt-4">
      <Elements stripe={stripePromise}>
        <MultiStepDemo />
      </Elements>
    </div>
  );
}

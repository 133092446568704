import React, { useEffect, useState } from "react";
import Breadcrumb from "../../common/Breadcrumb";
import ArtDetailImg from "../../assets/images/art-detail.png";
import Paragraph from "../../common/Paragraph";
import DummyProfile from "../../assets/images/dummy_profile.png";
import Button from "../../common/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { paths } from "../../routes/Paths";
import { useDispatch, useSelector } from "react-redux";
import { tokenSelector } from "../../redux/slices/userSlice";
import { FaPlayCircle } from "react-icons/fa";
import { Lightbox } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Video from "yet-another-react-lightbox/plugins/video";
import { getArtRoomDetailsById } from "../../services/profileService";
import { hideLoader, showLoader } from "../../redux/slices/siteLoaderSlice";
import { ArtDetails } from "../../interface/ArtDetails";
import { useTranslation } from "react-i18next";

function ArtRoomDetail() {
  const navigate = useNavigate();
  const token = useSelector(tokenSelector);
  const [videoUrl, setVideoUrl] = useState<string | undefined>(undefined);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const profileUrlToken = `${paths.web.profile}?token=${token}`;
  const artUrlToken = `${paths.web.artDetail}/${id}?token=${token}`;

  const [artDetails, setArtDetails] = useState<ArtDetails | undefined>(
    undefined
  );

  const getProfileImage = (url: string) => {
    const defaultAvatarUrl =
      "https://api.artgapi.com/uploads/icons/default_avatar.png";
    return url === defaultAvatarUrl ? DummyProfile : url;
  };

  const fetchArtRoomDetails = async (id: string) => {
    if (id) {
      dispatch(showLoader());
      try {
        const response = await getArtRoomDetailsById(parseInt(id));
        if (response?.data?.code === 200) {
          setArtDetails(response?.data?.art);
        }
      } catch (error) {
        console.error("Error fetching feed:", error);
      } finally {
        dispatch(hideLoader());
      }
    }
  };

  useEffect(() => {
    if (id) {
      fetchArtRoomDetails(id);
    }
  }, [id]);

  const openLightbox = (videoUrl: string) => {
    console.log("Opening lightbox with video URL:", videoUrl);
    setVideoUrl(videoUrl);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setVideoUrl(undefined);
  };

  const handleBuyNowClick = () => {
    const urlWithToken = `${paths.web.checkout}/${id}?token=${token}`;
    navigate(urlWithToken);
  };

  const breadcrumbPages = [
    { name: "Profile", path: profileUrlToken },
    { name: artDetails?.title || "", path: artUrlToken },
  ];

  return (
    <>
      <section>
        <div className="container">
          <div className="pt-4">
            <Breadcrumb pages={breadcrumbPages} />
          </div>
          <div className="md:py-[50px] py-[30px]">
            <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
              <div className="col-span-1">
                <div className="bg-light-grey w-full rounded-[20px] flex items-center justify-center">
                  {artDetails?.video ? (
                    <div className="relative">
                      <img
                        src={artDetails.video?.imageUrl}
                        className="max-h-[390px] w-full rounded-[20px] bg-light-grey cursor-default"
                        alt="video-thumbnail"
                      />
                      <span className="absolute inset-0 flex items-center justify-center ">
                        <FaPlayCircle
                          size={40}
                          className="text-base cursor-pointer"
                          onClick={() =>
                            openLightbox(artDetails?.video?.videoUrl)
                          }
                        />
                      </span>
                    </div>
                  ) : (
                    <img
                      src={
                        artDetails?.images && artDetails?.images[0]?.imageUrl
                      }
                      alt="artt-imgg"
                      className="inline-block max-h-[360px]"
                    />
                  )}
                </div>
              </div>
              <div className="col-span-1">
                <h1 className="md:text-[32px] sm:leading-[31px] text-[28px] leading-[27px] font-semibold text-site-black mb-4">
                  {artDetails?.title}
                </h1>
                <div className="flex items-center gap-2 mb-3">
                  <div className="md:w-[60px] md:h-[60px] w-[40px] h-[40px] rounded-full flex-shrink-0 overflow-hidden">
                    <img
                      src={getProfileImage(artDetails?.owner?.avatar ?? "")}
                      alt="profile-photo"
                    />
                  </div>
                  <Paragraph text16 className="font-medium">
                    <span className="text-site-dark-black/40 ">by</span>{" "}
                    {artDetails?.owner?.nickname}
                  </Paragraph>
                </div>
                {artDetails?.owner?.description && (
                  <Paragraph text18 className="mb-4 font-normal">
                    {artDetails?.owner?.description}
                  </Paragraph>
                )}
                <Paragraph text18 className="mb-2 font-normal">
                  <span className="font-medium">{t("profile.art")} | </span>
                  {artDetails?.width}*{artDetails?.height}cm
                </Paragraph>
                <Paragraph text18 className="mb-4 font-normal">
                  <span className="font-medium">
                    {t("profile.available_piece")} |{" "}
                  </span>
                  {artDetails?.quantity} {t("profile.copies_left")}.
                </Paragraph>
                <Paragraph text32 className="md:mb-[30px] mb-5">
                  €{artDetails?.price}
                </Paragraph>
                <Button
                  primary
                  className="w-full md:mb-[30px] mb-5"
                  onClick={handleBuyNowClick}
                  disabled={artDetails?.quantity === 0}
                >
                  {artDetails?.quantity === 0
                    ? t("profile.out_of_Stock")
                    : t("profile.buy_now")}
                </Button>

                {artDetails?.description && (
                  <>
                    <Paragraph text20 className="font-medium mb-3">
                      {t("profile.description")}
                    </Paragraph>
                    <Paragraph
                      text18
                      className="font-normal !text-dark-grey mb-4"
                    >
                      {artDetails?.description}
                    </Paragraph>
                  </>
                )}
                <ul className="flex items-center lg:gap-[65px] md:gap-7 sm:gap-5 gap-3">
                  <li>
                    <Paragraph text18 className="mb-[10px]">
                      {t("profile.weight")}
                    </Paragraph>
                    <Paragraph text18 className="">
                      {artDetails?.weight} kg
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph text18 className="mb-[10px]">
                      {t("profile.width")}
                    </Paragraph>
                    <Paragraph text18 className="">
                      {artDetails?.width} cm
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph text18 className="mb-[10px]">
                      {t("profile.height")}
                    </Paragraph>
                    <Paragraph text18 className="">
                      {artDetails?.height} cm
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph text18 className="mb-[10px]">
                      {t("profile.depth")}
                    </Paragraph>
                    <Paragraph text18 className="">
                      {artDetails?.depth} cm
                    </Paragraph>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {lightboxOpen && (
        <div>
          <Lightbox
            open={lightboxOpen}
            close={closeLightbox}
            slides={[
              {
                type: "video",
                sources: [{ src: videoUrl || "", type: "video/mp4" }],
              },
            ]}
            plugins={[Video]}
            carousel={{
              finite: true,
              padding: 0,
            }}
            video={{
              autoPlay: true,
              controls: true,
              playsInline: true,
              loop: false,
            }}
          />
        </div>
      )}
    </>
  );
}

export default ArtRoomDetail;

import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { CgClose } from "react-icons/cg";
import Paragraph from "./Paragraph";
import { ModalProps } from "../interface/Modal";

const Modal = ({
  open,
  onClose,
  header,
  children,
  width = "",
  headingText,
}: ModalProps) => {
  // Event handler for keydown event
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      if (onClose) {
        onClose();
      }
    }
  };

  // Add and remove event listener when modal is open/closed
  useEffect(() => {
    if (open) {
      document.addEventListener("keydown", handleKeyDown);
      // Add style to body to prevent scrolling
      document.body.style.overflow = "hidden";
    } else {
      document.removeEventListener("keydown", handleKeyDown);
      // Remove the style to restore scrolling
      document.body.style.overflow = "";
    }

    // Clean up the event listener and body style on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.body.style.overflow = "";
    };
  }, [open]);

  if (!open) return null;

  return ReactDOM.createPortal(
    <div
      onClick={onClose}
      className="fixed inset-0 flex justify-center items-center transition-colors z-[51] bg-site-black/60 "
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`bg-white rounded-lg shadow sm:p-6 p-4 transition-all md:max-h-[calc(110vh-160px)] max-h-[calc(110vh-120px)] overflow-y-auto scale-100 opacity-100 m-3 ${width}`}
      >
        {header && (
          <div
            className={`flex items-center justify-between gap-4 md:mb-5 mb-4 ${
              headingText ? "md:pb-5 pb-4 border-b border-medium-grey" : ""
            }`}
          >
            <Paragraph text24 className="">
              {headingText}
            </Paragraph>
            <button
              onClick={onClose}
              className="rounded-lg text-grey-400 bg-white hover:bg-grey-500 hover:text-grey-600"
            >
              <CgClose size={20} />
            </button>
          </div>
        )}
        {children}
      </div>
    </div>,
    document.getElementById("modal-root")!
  );
};

export default Modal;

import React, { useEffect, useState } from "react";
import Paragraph from "../../common/Paragraph";
import DummyProfile from "../../assets/images/dummy_logo.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { paths } from "../../routes/Paths";
import { useDispatch, useSelector } from "react-redux";
import { tokenSelector } from "../../redux/slices/userSlice";
import { FaThumbsUp } from "react-icons/fa";
import {
  getFeedComments,
  getFeedResponseComments,
} from "../../services/profileService";
import { hideLoader, showLoader } from "../../redux/slices/siteLoaderSlice";
import { CommentInterface } from "../../interface/Comment";
import About from "../about/About";
import { profileSelector } from "../../redux/slices/profileSlice";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { BsArrowLeft } from "react-icons/bs";
import ProfileHeader from "../header-profile/ProfileHeader";
import { ResponseCommentInterface } from "../../interface/ResponseComment";
import FeedSingleDetails from "./FeedSingleDetails";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataFound from "../../assets/images/no_data_found.svg";

function FeedCommentDetail() {
  const profileData = useSelector(profileSelector);
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const token = useSelector(tokenSelector);
  const itemsPerPage = 10;
  const [commentData, setCommentData] = useState<CommentInterface[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [responseCommentData, setResponseCommentData] = useState<
    Record<number, ResponseCommentInterface[]> // Change to store responses per comment ID
  >({});
  const [currentResponsePage, setCurrentResponsePage] = useState(1);
  const [responseHasMore, setResponseHasMore] = useState(true);
  const [selectedCommentId, setSelectedCommentId] = useState<number | null>(
    null
  );

  const timeDifference = (date: string) => {
    const today = moment();
    const createdDate = moment(date);
    let diff = today.diff(createdDate, "years");
    let message = t("profile.years_ago");
    if (diff === 0) {
      diff = today.diff(createdDate, "months");
      message = t("profile.months_ago");
      if (diff === 0) {
        diff = today.diff(createdDate, "weeks");
        message = t("profile.weeks_ago");
        if (diff === 0) {
          diff = today.diff(createdDate, "days");
          message = t("profile.days_ago");
          if (diff === 0) {
            diff = today.diff(createdDate, "hours");
            message = t("profile.hours_ago");
            if (diff === 0) {
              diff = today.diff(createdDate, "minutes");
              message = t("profile.minutes_ago");
            }
          }
        }
      }
    }
    if (diff === 0) {
      return t("profile.just_now");
    }
    return `${diff} ${message}`;
  };

  console.log("profileData", profileData);

  const fetchComments = async (page: number) => {
    if (id) {
      let queryString = `?page=${page}&limit=10`;
      dispatch(showLoader());

      try {
        const response = await getFeedComments(parseInt(id), queryString);
        const count = response?.data?.total || 0;
        if (response?.data?.comments) {
          setCommentData((prevData) => [
            ...prevData,
            ...response.data.comments,
          ]);
          setHasMore(page < Math.ceil(count / itemsPerPage));
        }
      } catch (error) {
        console.error("Error fetching feed:", error);
      } finally {
        dispatch(hideLoader());
      }
    } else {
      console.warn("profileData.id is undefined, skipping API call.");
      dispatch(hideLoader());
    }
  };

  const fetchResponseComments = async (page: number, id?: number) => {
    if (id) {
      let queryString = `?limit=10&page=${page}`;
      dispatch(showLoader());

      try {
        const response = await getFeedResponseComments(id, queryString);
        const count = response?.data?.total || 0;
        if (response?.data?.responses) {
          console.log("VISHWA", {
            ...responseCommentData,
            [id]: response.data.responses,
          });
          setResponseCommentData((prevReplies) => ({
            ...prevReplies, // Preserve previous replies for all comments
            [id]: [...(prevReplies[id] || []), ...response.data.responses], // Merge previous replies for this comment with the new ones
          }));

          setResponseHasMore(page < Math.ceil(count / itemsPerPage));
        }
      } catch (error) {
        console.error("Error fetching feed:", error);
      } finally {
        dispatch(hideLoader());
      }
    } else {
      console.warn("profileData.id is undefined, skipping API call.");
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    if (currentPage) {
      fetchComments(currentPage);
    }
  }, [currentPage, id]);

  useEffect(() => {
    if (currentResponsePage > 1) {
      fetchResponseComments(
        currentResponsePage,
        selectedCommentId || undefined
      );
    }
  }, [currentResponsePage, selectedCommentId]);

  const handleReplyToggle = (id: number) => {
    if (selectedCommentId === id) {
      // If the same comment is clicked, reset the selected comment ID and clear response comments
      setSelectedCommentId(null);
      setCurrentResponsePage(1);
      setResponseCommentData({}); // Clear response comments
    } else {
      // If a different comment is selected, fetch its replies and clear previous responses
      setSelectedCommentId(id);
      setCurrentResponsePage(1); // Reset to page 1 when a new comment is selected
      setResponseCommentData({}); // Clear previous response comments
      fetchResponseComments(1, id); // Fetch new responses for the selected comment
    }
  };

  const handleBack = () => {
    const urlWithToken = `${paths.web.profile}?token=${token}`;
    navigate(urlWithToken);
  };

  const getProfileImage = (url: string) => {
    const defaultAvatarUrl =
      "https://api.artgapi.com/uploads/icons/default_avatar.png";
    return url === defaultAvatarUrl ? DummyProfile : url;
  };

  console.log("commentData", responseCommentData);
  return (
    <>
      <ProfileHeader />
      <section className="md:py-10 py-[30px]">
        <div className="container">
          <div className="flex lg:flex-nowrap flex-wrap lg:gap-6 sm:gap-4 gap-3">
            {<About profileData={profileData} />}

            <div className="w-full">
              <div className="flex items-center gap-3 py-4 border-b border-light-grey mb-4">
                <BsArrowLeft
                  size={24}
                  onClick={handleBack}
                  className="cursor-pointer"
                />
                <Paragraph text24>{t("profile.comments")}</Paragraph>
              </div>
              <div className="">
                <FeedSingleDetails id={id || ""} />
                <InfiniteScroll
                  dataLength={commentData?.length}
                  next={() => {
                    if (hasMore) {
                      setCurrentPage((prevPage) => prevPage + 1); // Increment page number
                    }
                  }}
                  hasMore={hasMore}
                  loader={<h4>Loading ... </h4>}
                >
                  {commentData?.length === 0 ? (
                    <div className="break-inside-avoid text-center">
                      <img
                        src={NoDataFound}
                        alt="no-data-found"
                        className="mb-4 inline-block"
                      />
                      <Paragraph text24 className="mb-4 font-semibold">
                        {t("profile.sorry")}
                      </Paragraph>
                      <Paragraph text20 className="font-normal">
                        {t("profile.comment_no_data")}
                      </Paragraph>
                    </div>
                  ) : (
                    <ul>
                      {commentData?.map((item: any, index: any) => {
                        const isLastItem = index === commentData.length - 1;

                        return (
                          <li
                            className={`flex items-start justify-between gap-3 pb-4 border-b border-medium-grey mb-4 xl:w-[75%] ${
                              isLastItem ? "mb-0 border-b-0" : ""
                            }`}
                          >
                            <div className="flex items-start gap-2 w-full">
                              <div className="md:w-[42px] md:h-[42px] w-[36px] h-[36px] rounded-full flex-shrink-0 overflow-hidden">
                                <img
                                  src={getProfileImage(item?.user?.avatar)}
                                  alt="profile-photo"
                                />
                              </div>
                              <div className="w-full">
                                <Paragraph
                                  text16
                                  className="flex items-end gap-5 font-semibold !leading-[20px]"
                                >
                                  {item?.user?.name + " " + item?.user?.surname}
                                  <span className="text-dark-grey text-[14px] font-normal leading-[20px] relative before:absolute before:-left-3 before:w-[4px] before:h-[4px] before:bg-medium-grey before:rounded-full before:top-[6px]">
                                    {timeDifference(item?.createdAt ?? "")}
                                  </span>
                                </Paragraph>
                                <Paragraph
                                  text16
                                  className="flex items-end gap-2 !font-normal !leading-[20px]"
                                >
                                  {item?.text}
                                </Paragraph>

                                <div className="flex items-center gap-5 mt-[10px]">
                                  <div className=" inline-flex items-end gap-[6px]">
                                    <FaThumbsUp
                                      size={13}
                                      color={
                                        item?.likedByMe ? "#1AB53C" : "#828282"
                                      }
                                      className=""
                                    />
                                    <Paragraph
                                      text14
                                      className={`leading-[8px] !font-normal text-dark-grey`}
                                    >
                                      {item?.likers?.length}
                                    </Paragraph>
                                  </div>
                                  {item?.countResponses > 0 && (
                                    <Paragraph
                                      className="border-b cursor-pointer border-dark-grey !font-normal leading-[8px] relative before:absolute before:-left-3 before:w-[4px] before:h-[4px] before:bg-medium-grey before:rounded-full before:top-[2px]"
                                      onClick={() =>
                                        handleReplyToggle(item?.id)
                                      }
                                      text14
                                    >
                                      {selectedCommentId === item.id
                                        ? item?.countResponses > 1
                                          ? "Hide Replies"
                                          : "Hide Reply"
                                        : item?.countResponses > 1
                                        ? `${item?.countResponses} Replies`
                                        : `${item?.countResponses} Reply`}
                                    </Paragraph>
                                  )}
                                </div>

                                {selectedCommentId === item.id &&
                                  responseCommentData[item.id] && (
                                    <div
                                      id="scrollableDiv"
                                      className="max-h-[400px] overflow-y-auto border-red-600"
                                    >
                                      <InfiniteScroll
                                        dataLength={
                                          responseCommentData[item.id]?.length
                                        }
                                        next={() => {
                                          if (responseHasMore) {
                                            setCurrentResponsePage(
                                              (prevPage) => prevPage + 1
                                            ); // Increment page number
                                          }
                                        }}
                                        hasMore={responseHasMore}
                                        loader={<h4>Loading ... </h4>}
                                        // height={400}
                                        scrollableTarget="scrollableDiv"
                                        className="!overflow-hidden"
                                      >
                                        <ul className="">
                                          {responseCommentData[item.id].map(
                                            (respItem) => {
                                              return (
                                                <li className="flex items-start gap-3 mt-5">
                                                  <div className="md:w-[40px] md:h-[40px] w-[40px] h-[40px] rounded-full flex-shrink-0 overflow-hidden">
                                                    <img
                                                      src={getProfileImage(
                                                        respItem?.user?.avatar
                                                      )}
                                                      alt="profile-photo"
                                                    />
                                                  </div>
                                                  <div>
                                                    <Paragraph
                                                      text16
                                                      className="flex items-end gap-5 font-semibold !leading-[20px]"
                                                    >
                                                      {respItem?.user?.name +
                                                        " " +
                                                        respItem?.user?.surname}
                                                      <span className="text-dark-grey text-[14px] font-normal leading-[20px] relative before:absolute before:-left-3 before:w-[4px] before:h-[4px] before:bg-medium-grey before:rounded-full before:top-[6px]">
                                                        {timeDifference(
                                                          respItem?.createdAt ??
                                                            ""
                                                        )}
                                                      </span>
                                                    </Paragraph>
                                                    <Paragraph
                                                      text16
                                                      className="flex items-end gap-2 !font-normal !leading-[20px]"
                                                    >
                                                      {respItem?.text}
                                                    </Paragraph>

                                                    <div className="flex items-center gap-5 mt-[10px]">
                                                      <div className=" inline-flex items-end gap-[6px]">
                                                        <FaThumbsUp
                                                          size={13}
                                                          color={
                                                            respItem?.likedByMe
                                                              ? "#1AB53C"
                                                              : "#828282"
                                                          }
                                                          className=""
                                                        />
                                                        <Paragraph
                                                          text14
                                                          className="!text-dark-grey leading-[8px] !font-normal"
                                                        >
                                                          {
                                                            respItem?.likers
                                                              ?.length
                                                          }
                                                        </Paragraph>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      </InfiniteScroll>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FeedCommentDetail;

export const API = {
  PROFILE: {
    CHECK_USER: "v2/user/getProfileData",
    FEED: "v2/user",
    FEED_COMMENTS: "v2/web/comments",
    FEED_DETAILS: "v2/web/arts",
    LIKES: "v2/web/publication",
  },
  COMMON: {
    COUNTRIES: "countries",
  },
  CHECKOUT: {
    SHIPPING_ADDRESS: "v2/shipping/services",
    OTHER_SERVICES: "v2/shipping/transport/rate",
    PAYMENT_CHECKOUT: "v2/shipping/buy-art",
  },
};

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IUserInitialRedux {
  countries: Countries | null;
}

interface Countries {
  id?: number;
}

const initialState = {
  countries: null,
};

export const countrySlice = createSlice({
  name: "countries",
  initialState,
  reducers: {
    setCountries: (
      state: IUserInitialRedux,
      action: PayloadAction<Countries | null>
    ) => {
      state.countries = action.payload;
    },
  },
});

export const profileSelector = (state: { countries: IUserInitialRedux }) =>
  state.countries.countries;

const { actions, reducer } = countrySlice;

export const { setCountries } = actions;

export const setcountries = (data: Countries | null) => {
  setCountries(data);
};

export default reducer;

import React, { useEffect, useState } from "react";
import DummyProfile from "../../assets/images/dummy_logo.png";
import Paragraph from "../../common/Paragraph";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../redux/slices/siteLoaderSlice";
import { getFeedLikes } from "../../services/profileService";
import InfiniteScroll from "react-infinite-scroll-component";
import { LikeInterface } from "../../interface/Like";

interface LikesInterface {
  likeId: any;
}

function LikePopup({ likeId }: LikesInterface) {
  const [currentPage, setCurrentPage] = useState(1);
  const [likeData, setLikeData] = useState<LikeInterface[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const itemsPerPage = 10;

  const dispatch = useDispatch();

  const getFeedLIkes = async (page: number) => {
    if (likeId) {
      let queryString = `?page=${page}&limit=10`;
      dispatch(showLoader());

      try {
        const response = await getFeedLikes(likeId, queryString);
        const count = response?.data?.total || 0;
        if (response?.data?.data) {
          setLikeData((prevData) => [...prevData, ...response.data.data]);
          setHasMore(page < Math.ceil(count / itemsPerPage));
        }
      } catch (error) {
        console.error("Error fetching feed:", error);
      } finally {
        dispatch(hideLoader());
      }
    } else {
      console.warn("profileData.id is undefined, skipping API call.");
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    if (currentPage) {
      getFeedLIkes(currentPage);
    }
  }, [currentPage]);

  const getProfileImage = (url: string) => {
    const defaultAvatarUrl =
      "https://api.artgapi.com/uploads/icons/default_avatar.png";
    return url === defaultAvatarUrl ? DummyProfile : url;
  };

  return (
    <ul className="h-[352px] overflow-y-auto">
      <InfiniteScroll
        dataLength={likeData?.length}
        next={() => {
          if (hasMore) {
            setCurrentPage((prevPage) => prevPage + 1); // Increment page number
          }
        }}
        hasMore={hasMore}
        loader={<h4>Loading ... </h4>}
      >
        {likeData?.map((item: LikeInterface, index: number) => {
          const isLastItem = index === likeData.length - 1;
          return (
            <li
              className={`flex items-center gap-3 pb-2 mb-2 border-b border-[#f3f3f3] ${
                isLastItem ? "mb-0 border-b-0" : ""
              }`}
            >
              <div className="w-[40px] h-[40px] rounded-full flex-shrink-0 overflow-hidden">
                <img src={getProfileImage(item?.avatar)} alt="profile-photo" />
              </div>
              <Paragraph text16 className="">
                {item?.nickname}
              </Paragraph>
            </li>
          );
        })}
      </InfiniteScroll>
    </ul>
  );
}

export default LikePopup;

export const checkout = {
  basic_info: "Info de base.",
  shipping: "Expédition Adresse",
  full_Address: "Full Adresse",
  country: "Pays",
  city: "Ville",
  post_code: "Code postal",
  billing: "Facturation Adresse",
  payment_info: "Info de paiement",
  paymnet: "Info de paiement.",
  other_info: "Autres info.",
  carrier: "Transporteur",
  insurance: "Assurance",
  transport: "Valeur totale des marchandises liées au transport",
  shipment_fee: "Expédition frais",
  insurance_fee: "Assurance frais",
  service_fee: " Service frais",
  proceed_checkout: "Passer à la caisse",
  previous: "Précédente",
  confirm: "Confirmer",
  next: "Suivante",
  shipping_address: "Expédition Adresse.",
  form_fields: {
    name: {
      label: "Nom",
      required_error_message: "Le nom est requis",
      min_required_message: "Le nom doit contenir au moins 2 caractères",
    },
    email: {
      label: "Email",
      required_error_message: "L'email est requis",
      invalid_format: "Format d'email invalide",
    },
    contact_number: {
      label: "Numéro de contact",
      required_error_message: "Le numéro de téléphone est requis",
      min_error_message:
        "Le numéro de téléphone doit être composé de 8 à 15 chiffres",
      max_error_message:
        "Le numéro de téléphone doit être composé de 8 à 15 chiffres",
    },
    cardname: {
      label: "Nom de la carte",
      required_error_message: "Le nom du titulaire de la carte est requis",
    },
    cardno: {
      label: "Numéro de carte",
      required_error_message: "Le numéro de carte est requis",
      empty_message: "Le numéro de carte ne peut pas être vide",
    },
    expiry_date: {
      label: "Date d'expiration",
      required_error_message: "La date d'expiration de la carte est requis",
      empty_message: "La date d'expiration ne peut pas être vide",
    },
    cvc: {
      label: "CVC",
      required_error_message: "La carte CVC est requis",
      empty_message: "Card number cannot be empty",
    },
    full_Address: {
      label: "Full Adresse",
      shipping_required_error_message: "L'adresse de livraison est requis",
      billing_required_error_message: "L'adresse de facturation est requis",
    },
    city: {
      label: "Ville",
      shipping_required_error_message: "La ville d'expédition est requis",
      billing_required_error_message: "La ville de facturation est requis",
    },
    post_code: {
      label: "Code postal",
      shipping_required_error_message: "Le code postal d'expédition est requis",
      billing_required_error_message:
        "Le code postal de facturation est requis",
      min_shipping_required_error_message:
        "Le code postal d'expédition doit contenir au moins 5 caractères",
      max_shipping_required_error_message:
        "Le code postal d'expédition doit comporter au maximum 6 caractères",
      min_billing_required_error_message:
        "Le code postal de facturation doit contenir au moins 5 caractères",
      max_billing_required_error_message:
        "Le code postal de facturation doit comporter au maximum 6 caractères",
    },
    country: {
      label: "Pays",
      shipping_required_error_message: "Le pays d'expédition est requis",
      billing_required_error_message: "Le pays de facturation est requis",
    },
  },
};

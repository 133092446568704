import { API } from "../apiEndPoints/apiEndPoints";
import {
  axiosDelete,
  axiosGet,
  axiosPost,
  axiosPut,
} from "../axios/axiosMiddleware";

export const CheckShippingAddress = (data: object, id: number) => {
  return axiosPost(`${API.CHECKOUT.SHIPPING_ADDRESS}/${id}`, data);
};

export const CheckOtherServices = (data: object) => {
  return axiosPost(`${API.CHECKOUT.OTHER_SERVICES}`, data);
};

export const isPaymentSuccess = (data: object) => {
  return axiosPost(`${API.CHECKOUT.PAYMENT_CHECKOUT}`, data);
};

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IUserInitialRedux {
  paymentInfo: PaymentInfo | null;
}

interface subPriceInterface {
  subServiceCode?: string;
  sellPriceWithTaxes: number;
  sellPriceWithoutTaxesDiscounted?: number;
}

interface computedInterface {
  subServicesPricings: subPriceInterface[];
}
interface PaymentInfo {
  id?: number;
  code?: number;
  total: number;
  artgapiFees: number;
  transport: {
    computedPrices: computedInterface[];
    sellTotalPriceWithTaxes: number;
  };
}

const initialState = {
  paymentInfo: null,
};

export const paymentInfoSlice = createSlice({
  name: "paymentInfo",
  initialState,
  reducers: {
    setPaymentInfo: (
      state: IUserInitialRedux,
      action: PayloadAction<PaymentInfo | null>
    ) => {
      state.paymentInfo = action.payload;
    },
    discardPaymentInfo: (state: IUserInitialRedux) => {
      state.paymentInfo = null;
    },
  },
});

export const paymetInfoSelector = (state: { paymentInfo: IUserInitialRedux }) =>
  state.paymentInfo.paymentInfo;

const { actions, reducer } = paymentInfoSlice;

export const { setPaymentInfo, discardPaymentInfo } = actions;

export const setservices = (data: PaymentInfo | null) => {
  setPaymentInfo(data);
};

export default reducer;

/** @format */

import axios, { AxiosRequestConfig } from "axios";

import { Store } from "redux";
import { ToastShow } from "../redux/slices/toastSlice";
import { setUser } from "../redux/slices/userSlice";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const setupAxios = (store: Store) => {
  axios.interceptors.request.use((request) => {
    const storeData = store.getState();
    const authToken = storeData.user.token;
    if (authToken && !request.url?.includes("/countries")) {
      request.headers.Authorization = `Bearer ${authToken}`;
    }
    return request;
  });

  axios.interceptors.response.use(
    (res) => {
      store.dispatch(ToastShow({ message: res.data.message, type: "success" }));
      return res;
    },
    (e) => {
      const storeData = store.getState();

      if (storeData.user.token !== null) {
        if (
          e?.response?.data?.code === 401 ||
          e?.response?.data?.code === 403
        ) {
          if (e.response.data.message) {
            store.dispatch(
              ToastShow({
                message: e.response.data.message,
                type: "error",
              })
            );
          }
          //   store.dispatch(removeToken());
          store.dispatch(setUser(null));
        } else if (
          e?.response?.data?.code === 404 ||
          e?.response?.data?.code === 500
        ) {
          if (e.response.data.message) {
            store.dispatch(
              ToastShow({
                message: e.response.data.message,
                type: "error",
              })
            );
          }
        }
      } else {
        store.dispatch(
          ToastShow({
            message: e.response.data.message,
            type: "error",
          })
        );
      }
    }
  );
};

export default setupAxios;

export function axiosGet(url: string, data?: object) {
  return axios.get(`${BASE_URL}${url}`, {
    params: data,
  });
}

export function axiosPost(
  url: string,
  data: object,
  headers?: AxiosRequestConfig
) {
  return axios.post(`${BASE_URL}${url}`, data, headers ? headers : {});
}

export const axiosConfig = (
  method: string,
  url: string,
  config: AxiosRequestConfig,
  data: object
) => {
  return axios({
    method: method,
    url: `${BASE_URL}${url}`,
    ...config,
    data,
  });
};

export const axiosPatch = (url: string, data: object) => {
  return axios.patch(`${BASE_URL}${url}`, data);
};

export const axiosPut = (url: string, data: object) => {
  return axios.put(`${BASE_URL}${url}`, data);
};

export const axiosDelete = (url: string, data?: object) => {
  return axios.delete(`${BASE_URL}${url}`, { data });
};

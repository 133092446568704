import { API } from "../apiEndPoints/apiEndPoints";
import {
  axiosDelete,
  axiosGet,
  axiosPost,
  axiosPut,
} from "../axios/axiosMiddleware";

export const getCountries = () => {
  return axiosGet(`${API.COMMON.COUNTRIES}`);
};

export const getCountriesByInfo = (params: any) => {
  return axiosGet(`${API.COMMON.COUNTRIES}${params}`);
};

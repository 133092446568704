import React from "react";
import AppRoutes from "./routes/AppRoutes";
import "./web.css";
import "./App.css";
import ToastNotification from "./common/ToastNotifications";

function App() {
  return (
    <>
      <AppRoutes />
      <ToastNotification />
    </>
  );
}

export default App;

export const ProductStatus = {
  Exposed: "visible",
  OnSale: "sellable",
  Sold: "sold",
  OnStandBy: "standby",
  processing: "processing",
};
export const DEFAULT_DEVISE = "€";
export const CONSTANTS = {
  FRENCH_ABV: "fr",
  ENGLISH_ABV: "en",
};

import { Profile } from "../components";
import ArtRoomDetail from "../components/art-room-detail/ArtRoomDetail";
import Checkout from "../components/checkout/Checkout";
import FeedCommentDetail from "../components/feed-comment/FeedComment";
import { paths } from "./Paths";

export const RoutesPath = [
  //   {
  //     path: "/:utm_term?",
  //     // path: "",
  //     name: "Home",
  //     element: Home,
  //   },
  {
    path: paths.web.profile,
    name: "Profile",
    element: Profile,
    isback: false,
  },
  {
    path: `${paths.web.artDetail}/:id`,
    name: "Art Detail",
    element: ArtRoomDetail,
    isback: false,
  },
  {
    path: `${paths.web.commentDetail}/:id`,
    name: "Comment Detail",
    element: FeedCommentDetail,
    isback: false,
  },
  {
    path: `${paths.web.checkout}/:id`,
    name: "Checkout",
    element: Checkout,
    isback: false,
  },
];

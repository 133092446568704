import { ErrorMessage, Field, useFormikContext } from "formik";
import React, { useState } from "react";
import InputType from "../../../common/form-components/InputType";
import { useTranslation } from "react-i18next";
import Paragraph from "../../../common/Paragraph";
import FormLabel from "../../../common/form-components/FormLabel";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";

const PaymentInfo = () => {
  const { t } = useTranslation();

  const { values, errors, touched, setFieldError, setFieldValue } =
    useFormikContext<{
      cardHolderName: string;
      cardNumber: string;
      cardExpiry: string;
      cardCvc: string;
    }>();

  const [cardErrors, setCardErrors] = useState({
    cardNumber: "",
    cardExpiry: "",
    cardCvc: "",
  });
  console.log("fgdfgdfgd", errors);
  const handleCard = (change: any) => {
    const { error, complete, elementType, empty } = change || {};
    if (error && !complete) {
      setCardErrors((prevErrors) => ({
        ...prevErrors,
        [elementType]: error.message,
      }));
    } else if (empty) {
      let errorMessage = "";

      switch (elementType) {
        case "cardNumber":
          errorMessage = t("checkout.form_fields.cardno.empty_message");
          break;
        case "cardExpiry":
          errorMessage = t("checkout.form_fields.expiry_date.empty_message");
          break;
        case "cardCvc":
          errorMessage = t("checkout.form_fields.cvc.empty_message");
          break;
        default:
          errorMessage = `${elementType} cannot be empty`;
      }
      setCardErrors((prevErrors) => ({
        ...prevErrors,
        [elementType]: errorMessage,
      }));
    } else if (!error && complete) {
      setCardErrors((prevErrors) => ({
        ...prevErrors,
        [elementType]: "",
      }));
      setFieldValue(elementType, "trgdfg");
      setFieldError(elementType, "");
    }
  };
  return (
    <div className="md:pt-10 pt-7 w-full">
      <Paragraph text20 className="font-medium mb-4">
        {t("checkout.payment_info")}.
      </Paragraph>
      <div className="grid grid-cols-12 lg:gap-6 gap-3 mb-4">
        <div className="md:col-span-6 col-span-12">
          <FormLabel>
            {t("checkout.form_fields.cardname.label")}
            <span className="text-site-red">*</span>
          </FormLabel>
          <InputType
            name="cardHolderName"
            placeholder="Enter name of card"
            value={values.cardHolderName}
          />
        </div>

        <div className="md:col-span-6 col-span-12">
          <FormLabel>
            {t("checkout.form_fields.cardno.label")}
            <span className="text-site-red">*</span>
          </FormLabel>
          {/* Stripe's CardNumberElement */}
          <div className="border border-medium-grey py-[13px] px-[15px] focus:border-dark-grey rounded-lg appearance-none text-base leading-5 w-full font-normal ">
            <CardNumberElement
              onChange={(e) => handleCard(e)}
              id="Card Number"
            />
          </div>

          {cardErrors?.cardNumber ? (
            <div className="text-site-red text-sm font-medium">
              {cardErrors.cardNumber}
            </div>
          ) : (
            <div className="text-site-red text-sm font-medium">
              {errors?.cardNumber}
            </div>
          )}
        </div>
        <div className="md:col-span-6 col-span-12">
          <FormLabel>
            {t("checkout.form_fields.expiry_date.label")}
            <span className="text-site-red">*</span>
          </FormLabel>
          {/* Stripe's CardExpiryElement */}
          <div className="border border-medium-grey py-[13px] px-[15px] focus:border-dark-grey rounded-lg appearance-none text-base leading-5 w-full font-normal ">
            <CardExpiryElement onChange={(e) => handleCard(e)} />
          </div>
          {cardErrors?.cardExpiry ? (
            <div className="text-site-red text-sm font-medium">
              {cardErrors.cardExpiry}
            </div>
          ) : (
            <div className="text-site-red text-sm font-medium">
              {errors?.cardExpiry}
            </div>
          )}
        </div>
        <div className="md:col-span-6 col-span-12">
          <FormLabel>
            {t("checkout.form_fields.cvc.label")}
            <span className="text-site-red">*</span>
          </FormLabel>
          {/* Stripe's CardCvcElement */}
          <div className="border border-medium-grey py-[13px] px-[15px] focus:border-dark-grey rounded-lg appearance-none text-base leading-5 w-full font-normal ">
            <CardCvcElement onChange={(e) => handleCard(e)} />
          </div>
          {cardErrors?.cardCvc ? (
            <div className="text-site-red text-sm font-medium">
              {cardErrors.cardCvc}
            </div>
          ) : (
            <div className="text-site-red text-sm font-medium">
              {errors?.cardCvc}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentInfo;

import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, useFormikContext } from "formik";
import InputType from "../../../common/form-components/InputType";
import Paragraph from "../../../common/Paragraph";
import FormLabel from "../../../common/form-components/FormLabel";
import { useTranslation } from "react-i18next";
import {
  getCountries,
  getCountriesByInfo,
} from "../../../services/countryService";

const BasicInfo = () => {
  const { t } = useTranslation();
  const [countryList, setCountryList] = useState<
    { code: string; name: string }[]
  >([]);
  const { values, errors, setFieldValue, handleBlur } = useFormikContext<{
    name: string;
    email: string;
    phone_number: string;
    phone_country_code: string;
  }>();

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        let queryString = `?sort_by=code&order=asc`;

        const response = await getCountriesByInfo(queryString);
        setCountryList(response?.data?.countries || []);
      } catch (error) {
        console.error("Error validating token:", error);
      }
    };

    fetchCountries();
  }, []);

  return (
    <div className="md:pt-10 pt-7 w-full">
      <Paragraph text20 className="font-medium mb-4">
        {t("checkout.basic_info")}
      </Paragraph>
      <div className="grid grid-cols-12 lg:gap-6 gap-3 mb-4">
        <div className="col-span-12">
          <FormLabel>
            {t("checkout.form_fields.name.label")}
            <span className="text-site-red">*</span>
          </FormLabel>
          <InputType
            name="name"
            placeholder="Enter name"
            value={values.name}
            type="text"
          />
        </div>
        <div className="md:col-span-6 col-span-12">
          <FormLabel>
            {t("checkout.form_fields.email.label")}
            <span className="text-site-red">*</span>
          </FormLabel>
          <InputType
            name="email"
            placeholder="Enter email"
            value={values.email}
            onBlur={handleBlur}
          />
        </div>
        <div className="md:col-span-6 col-span-12">
          <FormLabel>
            {t("checkout.form_fields.contact_number.label")}
            <span className="text-site-red">*</span>
          </FormLabel>
          <div className="flex items-stretch w-full border rounded-lg focus-within:border-dark-grey">
            <div className="pl-[15px] pr-[10px] py-[11px] border-medium-grey relative before:absolute before:right-0 before:h-5 before:w-[1px] before:bg-medium-grey before:rounded-[2px] ">
              <select
                name="phone_country_code"
                className="text-base leading-[15px] font-normal bg-transparent cursor-pointer"
                value={values.phone_country_code}
                onChange={(e) =>
                  setFieldValue("phone_country_code", e.target.value)
                }
              >
                {countryList?.map((country, index) => (
                  <option key={country.code + index} value={country.code}>
                    {country.code}
                  </option>
                ))}
              </select>
            </div>
            <InputType
              type={"number"}
              fullWidth
              name={"phone_number"}
              placeholder={"Enter contact number"}
              className={"border-0"}
              value={values.phone_number}
              showError={false}
            />
          </div>
          {errors?.phone_number && (
            <div className="text-site-red text-sm font-medium">
              {errors?.phone_number}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;

import React from "react";
import Paragraph from "../../common/Paragraph";
import { useTranslation } from "react-i18next";

function About({ profileData }: any) {
  const { t } = useTranslation();
  return (
    <div className="lg:w-[410px] w-full lg:flex-shrink-0">
      <div className="bg-light-grey md:p-[30px] p-5 rounded-xl  sticky top-[120px]">
        <Paragraph text20 className="font-medium mb-[10px]">
          {t("profile.about_artist")}
        </Paragraph>
        <Paragraph text18 className="text-site-dark-black font-normal">
          {profileData?.description ? profileData?.description : "--"}
        </Paragraph>
      </div>
    </div>
  );
}

export default About;

import React from "react";
import Paragraph from "../../common/Paragraph";
import { FaMapMarkerAlt } from "react-icons/fa";
import DummyProfile from "../../assets/images/dummy_logo.png";
import SlidingTabBar from "../../common/SlidingTabBar";
import About from "../about/About";
import Feed from "../feed/Feed";
import { Tab } from "../../interface/Tabs";
import ArtRoom from "../art-room/ArtRoom";
import { useSelector } from "react-redux";
import { profileSelector } from "../../redux/slices/profileSlice";
import { useTranslation } from "react-i18next";
import ProfileHeader from "../header-profile/ProfileHeader";

function Profile() {
  const profileData = useSelector(profileSelector);
  const { t } = useTranslation();

  const tabs: Tab[] = [
    { id: "feed", name: t("profile.feed"), content: <Feed /> },
    { id: "art_room", name: t("profile.artroom"), content: <ArtRoom /> },
  ];

  return (
    <>
      <ProfileHeader />

      <section className="md:py-10 py-[30px]">
        <div className="container">
          <div className="flex lg:flex-nowrap flex-wrap lg:gap-6 sm:gap-4 gap-3">
            {/* {profileData?.description && <About profileData={profileData} />} */}
            {<About profileData={profileData} />}

            <div className="w-full">
              <SlidingTabBar tabs={tabs} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Profile;

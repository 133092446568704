import { createSlice } from "@reduxjs/toolkit";

export interface ISiteLoaderType {
  isConfirmService: boolean;
}

const initialState: ISiteLoaderType = {
  isConfirmService: false,
};

export const isConfirmServicesSlice = createSlice({
  name: "isConfirmService",
  initialState,
  reducers: {
    isConfirmService: (state) => {
      state.isConfirmService = true;
    },
    notConfirmService: (state) => {
      state.isConfirmService = false;
    },
  },
});

export const isConfirmServiceSelector = (state: {
  isConfirmService: ISiteLoaderType;
}) => {
  return state.isConfirmService.isConfirmService;
};

const { actions, reducer } = isConfirmServicesSlice;

export const { isConfirmService, notConfirmService } = actions;

export default reducer;

import * as Yup from "yup";

export default [
  Yup.object({
    name: Yup.string()
      .trim()
      .required("Le nom est requis")
      .min(2, "Le nom doit contenir au moins 2 caractères"),
    email: Yup.string()
      .trim()
      .email("Format d'email invalide")
      .required("L'email est requis"),
    phone_number: Yup.string()
      .min(8, "Le numéro de téléphone doit être composé de 8 à 15 chiffres")
      .max(15, "Le numéro de téléphone doit être composé de 8 à 15 chiffres")
      .required("Le numéro de téléphone est requis"),
  }),
  Yup.object({
    cardHolderName: Yup.string()
      .trim()
      .required("Le nom du titulaire de la carte est requis"),
    cardNumber: Yup.string().trim().required("Le numéro de carte est requis"),
    cardExpiry: Yup.string()
      .trim()
      .required("La date d'expiration de la carte est requis"),
    cardCvc: Yup.string().trim().required("La carte CVC est requis"),
  }),
  Yup.object({
    shipping_address: Yup.string()
      .trim()
      .required("L'adresse de livraison est requis"),
    shipping_city: Yup.string()
      .trim()
      .required("La ville d'expédition est requis"),
    shipping_zip: Yup.string()
      .trim()
      .min(5, "Le code postal d'expédition doit contenir au moins 5 caractères")
      .max(
        6,
        "Le code postal d'expédition doit comporter au maximum 6 caractères"
      )
      .required("Le code postal d'expédition est requis"),
    shiiping_country: Yup.string()
      .trim()
      .required("Le pays d'expédition est requis"),
    billing_address: Yup.string()
      .trim()
      .required("L'adresse de facturation est requis"),
    billing_city: Yup.string()
      .trim()
      .required("La ville de facturation est requis"),
    billing_zip: Yup.string()
      .trim()
      .min(
        5,
        "Le code postal de facturation doit contenir au moins 5 caractères"
      )
      .max(
        6,
        "Le code postal de facturation doit comporter au maximum 6 caractères"
      )
      .required("Le code postal de facturation est requis"),
    blilling_country: Yup.string()
      .trim()
      .required("Le pays de facturation est requis"),
  }),
];

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Interest } from "../../interface/Interest";

export interface IUserInitialRedux {
  profile: Profile | null;
}

interface Country {
  id: number;
  name: string;
  code: string;
}

interface Profile {
  id?: number;
  avatar: string;
  country: Country;
  followers: number;
  post: number;
  nickname: string;
  description: string;
  interest: Interest[];
  phoneNb?: number;
}

const initialState = {
  profile: null,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile: (
      state: IUserInitialRedux,
      action: PayloadAction<Profile | null>
    ) => {
      state.profile = action.payload;
    },
  },
});

export const profileSelector = (state: { profile: IUserInitialRedux }) =>
  state.profile.profile;

const { actions, reducer } = profileSlice;

export const { setProfile } = actions;

export const setprofile = (data: Profile | null) => {
  setProfile(data);
};

export default reducer;

export const profile = {
  followers: "Followers",
  post: "Post",
  interest: "Artist’s Interest",
  about_artist: "About Artist",
  feed: "Feed",
  artroom: "Art Room",
  weeks_ago: "weeks ago",
  years_ago: "years ago",
  months_ago: "months ago",
  days_ago: "days ago",
  hours_ago: "hours ago",
  minutes_ago: "minutes ago",
  just_now: "just now",
  sorry: "Sorry!",
  no_data: "We haven’t found any data",
  out_of_Stock: "Out Of Stock",
  view_more: "View More",
  sale: "For Sale",
  exhibited: "Exhibited",
  art: "Art",
  available_piece: "Available Piece",
  copies_left: "copies left only",
  buy_now: "Buy Now",
  description: "Description",
  weight: "Weight",
  width: "Width",
  height: "Height",
  depth: "Depth",
  price: "Price",
  comment_no_data: "No comments found",
  comments: "Comments",
  likes: "Likes",
};

import { API } from "../apiEndPoints/apiEndPoints";
import {
  axiosDelete,
  axiosGet,
  axiosPost,
  axiosPut,
} from "../axios/axiosMiddleware";

export const CheckUser = (data: object) => {
  return axiosPost(`${API.PROFILE.CHECK_USER}`, data);
};

export const getFeedById = (id: number, query?: string) => {
  return axiosGet(`${API.PROFILE.FEED}/${id}/getFeedArtroomData${query}`);
};

export const getArtRoomDetailsById = (id: number) => {
  return axiosGet(`${API.PROFILE.FEED}/${id}`);
};

export const getFeedComments = (id: number, query?: string) => {
  return axiosGet(`${API.PROFILE.FEED_COMMENTS}/${id}${query}`);
};

export const getFeedDetailsById = (id: number) => {
  return axiosGet(`${API.PROFILE.FEED_DETAILS}/${id}`);
};

export const getFeedResponseComments = (id: number, query?: string) => {
  return axiosGet(`${API.PROFILE.FEED_COMMENTS}/${id}/responses${query}`);
};

export const getFeedLikes = (id: number, query?: string) => {
  return axiosGet(`${API.PROFILE.LIKES}/${id}/likes${query}`);
};

export const checkout = {
  basic_info: "Basic Info.",
  shipping: "Shipping Address",
  full_Address: "Full address",
  country: "Country",
  city: "City",
  post_code: "Post code",
  billing: "Billing Address",
  payment_info: "Payment Info",
  paymnet: "Payment Info.",
  other_info: "Other Info.",
  carrier: "Carrier",
  insurance: "Insurance",
  transport: "Total values of transport-related goods",
  shipment_fee: "Shipment fee",
  insurance_fee: "Insurance fee",
  service_fee: " Service fee",
  proceed_checkout: "Proceed to Checkout",
  previous: "Previous",
  confirm: "Confirm",
  next: "Next",
  shipping_address: "Shipping Address.",
  form_fields: {
    name: {
      label: "Name",
      required_error_message: "Name is required",
      min_required_message: "Name must be at least 2 characters",
    },
    email: {
      label: "Email",
      required_error_message: "Email is required",
      invalid_format: "Invalid email format",
    },
    contact_number: {
      label: "Contact number",
      required_error_message: "Phone number is required",
      min_error_message: "Phone number should be 8 to 15 numeric",
      max_error_message: "Phone number should be 8 to 15 numeric",
    },
    cardname: {
      label: "Name of card",
      required_error_message: "Card holder name is required",
    },
    cardno: {
      label: "Card number",
      required_error_message: "Card number is required",
      empty_message: "Card number cannot be empty",
    },
    expiry_date: {
      label: "Expiration Date",
      required_error_message: "Card expiry date is required",
      empty_message: "Expiry date cannot be empty",
    },
    cvc: {
      label: "CVC",
      required_error_message: "Card cvc is required",
      empty_message: "Le CVC ne peut pas être vide",
    },
    full_Address: {
      label: "Full address",
      shipping_required_error_message: "Shipping address is required",
      billing_required_error_message: "Billing address is required",
    },
    city: {
      label: "City",
      shipping_required_error_message: "Shipping city is required",
      billing_required_error_message: "Billing city is required",
    },
    post_code: {
      label: "Post code",
      shipping_required_error_message: "Shipping zip is required",
      billing_required_error_message: "Billing zip is required",
      min_shipping_required_error_message:
        "Shipping zip must be at least 5 characters",
      max_shipping_required_error_message:
        "Shipping zip must be at most 6 characters",
      min_billing_required_error_message:
        "Billing zip must be at least 5 characters",
      max_billing_required_error_message:
        "Billing zip must be at most 6 characters",
    },
    country: {
      label: "Country",
      shipping_required_error_message: "Shipping country is required",
      billing_required_error_message: "Billing country is required",
    },
  },
};

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IUserInitialRedux {
  services: Services | null;
}

interface computedservices {
  serviceCode: string;
  sell: {
    includingTaxesPrice: number;
  };
}

interface Services {
  id?: number;
  code?: number;
  buyerCityId: number;
  sellerCityId: number;
  transportServices: {
    computedServicesPrices: computedservices[];
  };
  insurance?: boolean;
}

const initialState = {
  services: null,
};

export const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    setServices: (
      state: IUserInitialRedux,
      action: PayloadAction<Services | null>
    ) => {
      state.services = action.payload;
    },
    discardOtherServices: (state: IUserInitialRedux) => {
      state.services = null;
    },
  },
});

export const servicesSelector = (state: { services: IUserInitialRedux }) =>
  state.services.services;

const { actions, reducer } = servicesSlice;

export const { setServices, discardOtherServices } = actions;

export const setservices = (data: Services | null) => {
  setServices(data);
};

export default reducer;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en";
import fr from "./locales/fr";
import { CONSTANTS } from "./constant/constant";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      fr: {
        translation: fr,
      },
      en: {
        translation: en,
      },
    },
    lng: CONSTANTS.FRENCH_ABV,
    fallbackLng: CONSTANTS.FRENCH_ABV,
    interpolation: {
      escapeValue: false,
    },
  });

export function strings(name: string, params = {}) {
  return i18n.t(name, params);
}

export default i18n;
